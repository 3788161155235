import React from "react";

const Alert = ({ alert }) => {
  return (
    <div className="well alert-container">
      <div className="alert-icon">
        <i className="icon glyphicon glyphicon-info-sign"></i>
      </div>
      <div
        className="alert-copy"
        dangerouslySetInnerHTML={{ __html: alert }}
      />
    </div>
  )
};

export { Alert };
