import React from "react";
import general from "general/general";

function useAlert(endpoint) {
  const [alert, setAlert] = React.useState(null);
  React.useEffect(() => {
    async function _fetchAlert() {
      const result = await general.callApi(
        endpoint,
        "GET",
        {},
        null,
        null,
        null,
        true,
        false,
        true
      );
      if (result.alert) {
        setAlert(result.alert);
      }
    }
    _fetchAlert();
  }, [endpoint]);

  return [alert, setAlert];
}

export { useAlert };
