export default {
  disabledClass: "disabled",
  activeClass: "is-active",
  hiddenClass: "hidden",
  errorClass: "has-error",
  activeSearchClass: "is-search-active", // toggles search form
  scrollClass: "is-scrolled", // positions header at -100% fixed
  scrollVisibleClass: "is-scrolled-visible", // positions header at 0 fixed
  duration: 500,
  speed: 500,
  delay: 4000,
  vpLg: 768,
  vpXLg: 1024,
  sessionAgeInDays: 7,
  DURATIONS: [
    { value: 10, label: "10 minutes" },
    { value: 15, label: "15 minutes" },
    { value: 20, label: "20 minutes" },
    { value: 30, label: "30 minutes" },
    { value: 40, label: "40 minutes" },
    { value: 45, label: "45 minutes" },
    { value: 60, label: "1 hour" },
    { value: 75, label: "1 hour 15 minutes" },
    { value: 90, label: "1 hour 30 minutes" },
    { value: 105, label: "1 hour 45 minutes" },
    { value: 120, label: "2 hours" },
    { value: 135, label: "2 hours 15 minutes" },
    { value: 150, label: "2 hours 30 minutes" },
    { value: 180, label: "3 hours" },
  ],
  MIN_APPOINTMENT_DURATION_IN_MINUTES: 5,
  MAX_APPOINTMENT_DURATION_IN_MINUTES: 180,
  MIN_UNAVAILABLE_APPOINTMENT_DURATION_IN_MINUTES: 15,
  REASON_TYPES: [
    {
      label: "In Person",
      value: "in_person",
    },
    {
      label: "Virtual",
      value: "virtual",
    },
    {
      label: "Telephonic",
      value: "telephonic",
    },
    {
      label: "Home Visit",
      value: "home_visit",
    },
  ],
  VIRTUAL_PROVIDERS: [
    {
      label: "RecoMed Telehealth Platform",
      value: "recomed"
    },
    {
      label: "External Telehealth Platform (e.g: Skype, Zoom, ...)",
      value: "external"
    }
  ]
};

export const PASSWORD_STRENGTH_PATTERN = /^(?=.*?[A-Z])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const PASSWORD_STRENGTH_ERROR = "Password must be 8 characters or more, with 1 upper and 1 special character";
