import React from "react";

const Dropdown = (props) => {
  const [selected, setSelected] = React.useState(props.selected || "");
  const data =
    typeof props.data == "string" ? JSON.parse(props.data) : props.data;

  const Options = ({ options }) => {
    return (
      <React.Fragment>
        {options.map(({ label, value }, index) => (
          <option key={index + 1} value={value}>
            {label}
          </option>
        ))}
      </React.Fragment>
    );
  };

  return (
    <div className="rm--dropdown">
      <select
        id={props.id}
        name={props.name}
        value={selected}
        onChange={(event) => {
          setSelected(event.target.value);
          if (props.onChange) {
            props.onChange(event);
          }
        }}
      >
        {props.default && (
          <option key="0" value={props.default}>
            {props.placeholder || props.default}
          </option>
        )}
        {Array.isArray(props.data[0]) ? (
          props.data.length > 1 ? (
            props.data.map(([label, options]) => (
              <optgroup key={label} label={label}>
                <Options options={options} />
              </optgroup>
            ))
          ) : (
            <Options options={props.data[0][1]} />
          )) : (
          <Options options={props.data} />
        )}
      </select>
    </div>
  );
};

export default Dropdown;
