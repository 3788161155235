import localSession from "general/localSession";

const user = () => {
  const [getUser] = localSession.user;
  return getUser() ? 1 : 0;
};
const workplaces = () => {
  const [getUser] = localSession.user;
  return getUser().total_workplaces;
};
const practitioners = () => {
  const [getWorkplace] = localSession.workplace;
  return getWorkplace().practitioners;
};
const CLAUSES = {
  hasUser: [() => user() > 0, "No User"],
  hasWorkplace: [() => workplaces() > 0, "No Workplace"],
  hasPractitioner: [() => practitioners().length > 0, "No Practitioner"],
};

export default function guard(clauses) {
  let pass = true;
  for (const clauseKey of clauses) {
    if (Object.keys(CLAUSES).includes(clauseKey)) {
      const [func, err] = CLAUSES[clauseKey];
      if (!func()) {
        pass = false;
        break;
      }
    }
  }

  return pass;
}
