import general from "general";
import isString from "lodash/isString";

let resourceCache = {};

function notImplemented() {
    throw new Error("Not Implemented");
}

/**
 * A custom Error class used to properly handle Promise failures
 */
class ResourceError extends Error {
    constructor(message, error) {
        super(message);
        this.message = message;
        this.error = error;
        this.name = "ResourceError";
    }
}

/**
 *
 * @param {string} [prefix=""]
 * @returns {resource}
 */
function getResourceFunction(prefix) {
    var prefix = isString(prefix) ? prefix : "";
    /**
     *
     * @param resourceName
     * @returns {{get: self.get, refresh: self.refresh, post: self.post, resource: resource, id: self.id}}
     */
    function resource(resourceName) {
        var resourceName = (prefix + resourceName).toLowerCase(),
            resourcePath = resourceName + (resourceName.endsWith("/") ? "" : "/");

        return Object.defineProperties(
            getResourceFunction(resourcePath),
            {
                list: {
                    /**
                     * Returns a Promise for an iterator over the requested resource
                     * instance.
                     *
                     * NOT IMPLEMENTED for now until we move to ES6+ and get generators.
                     *
                     * @param {object}  filters
                     * @param {boolean} [useCache=true]
                     * @returns {Promise}
                     */
                    value: notImplemented
                },
                post: {
                    /**
                     * Returns a Promise for a POST to the requested resource.
                     *
                     * @param {object} [data]
                     * @param {object} [headers]
                     * @returns {Promise}
                     */
                    value(data, headers) {
                        // TODO: When list is implemented this will need to be
                        // TODO: modified to invalidate the result of caching the get.
                        return new Promise(function (resolve, reject) {
                            // TODO: Find a way to cache returned objects as though they had been retrieved by get (maybe analyse resource_uri property?)
                            general.callApi(
                                resourcePath, "POST", data, null, resolve,
                                error => {
                                    reject(new ResourceError(
                                        "Failed to POST to " + resourcePath,
                                        error));
                                },
                                true, false, true, headers);
                        });
                    }
                },
                put: {
                    /**
                     * TODO: Implement
                     *
                     * @param {data}
                     * @returns {Promise}
                     */
                    value: notImplemented
                },
                get: {
                    /**
                     * Returns a Promise for the requested resource. This
                     * function assumes the result of a GET to the resource
                     * in question will be an object representing a
                     * single resource instance. For GETs that return
                     * multiple results use the list function.
                     *
                     * Will cache the result (whether successful or not) of
                     * attempting to retrieve the requested resource instance
                     * unless the useCache parameter is passed a boolean
                     * false value.
                     *
                     * @param {object} [data]
                     * @param {boolean} [useCache=true]
                     * @param {object} [headers]
                     * @returns {Promise}
                     */
                    value(data, useCache=true, headers) {
                        return new Promise(function (resolve, reject) {
                            var resource = resourceCache[resourcePath];
                            if (useCache && typeof(resource) == "object") {
                                if (resource.errorObject) {
                                    reject(new ResourceError(
                                        "Cached failed to GET " + resourcePath,
                                        resource.errorObject
                                    ));
                                }
                                else if (resource.resourceObject) resolve(resource.resourceObject);
                                else throw new Error("Malformed cached resource object for " + resourcePath);
                            } else general.callApi(
                                resourcePath, "GET", data, null,
                                resourceObject => {
                                    resourceCache[resourcePath] = {
                                        resourceObject: resourceObject
                                    };
                                    resolve(resourceObject);
                                },
                                errorObject => {
                                    resourceCache[resourcePath] = {
                                        errorObject: errorObject
                                    };
                                    reject(new ResourceError(
                                        "Failed to GET " + resourcePath,
                                        errorObject
                                    ));
                                }, true, false, true, headers);
                        });

                    }
                },
                refresh: {
                    /**
                     * Returns a Promise for the requested resource that is
                     * guaranteed to be fresh.
                     *
                     * This is just a short-cut for calling resource.get(false)
                     * with the aim being to improve readability.
                     *
                     * @param {object} [data]
                     * @param {object} [headers]
                     * @returns {Promise}
                     */
                    value(data, headers) { return this.get(data, false, headers); }
                },
                patch: {
                    /**
                     * Returns a Promise for a PATCH to the requested
                     * resource.
                     *
                     * If the PATCH is successful the result object will
                     * be cached.
                     *
                     * @param {object} [data]
                     * @param {object} [headers]
                     * @returns {Promise}
                     */
                    value(data, headers) {
                        return new Promise(function (resolve, reject) {
                            general.callApi(
                                resourcePath, "PATCH", data, null,
                                resourceObject => {
                                    // TODO: Handle resources which don't return an object by invalidating the cached object instead
                                    resourceCache[resourcePath] = {
                                        resourceObject: resourceObject
                                    };
                                    resolve(resourceObject);
                                },
                                error => {
                                    reject(new ResourceError(
                                        "Failed to PATCH " + resourcePath,
                                        error));
                                }, true, false, true, headers);
                        });
                    }

                },
                delete: {
                    /**
                     * Returns a Promise for a DELETE to the requested resource.
                     *
                     * If the DELETE is successful the cached copy of the
                     * requested resource will be expunged.
                     *
                     * @param {object} [data]
                     * @param {object} [headers]
                     * @returns {Promise}
                     */
                    value(data, headers) {
                        return new Promise(function (resolve, reject) {
                            general.callApi(
                                resourcePath, "DELETE", data, null,
                                response => {
                                    resourceCache[resourcePath] = null;
                                    resolve(response);
                                },
                                error => {
                                    reject(new ResourceError(
                                        "Failed to DELETE " + resourcePath,
                                        error));
                                }, true, false, true, headers);
                        });
                    }

                },
                resource: {
                    /**
                     * A more verbose way of calling resource("child") is to
                     * call resource.resource("child")
                     *
                     * @param {string} resourceName
                     * @returns {resource}
                     */
                    value(resourceName) {
                        return this(resourceName);
                    }
                }
            }
        );
    }
    return resource;
}

export default getResourceFunction()
