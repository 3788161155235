import localSession from "general/localSession";

var exports = {
    refresh: function () {
        const [getUser] = localSession.user;
        const user = getUser();
        var loginButtonContainers = $(".login-button-container"),
            loggedInDropdown = $('.logged-in__dropdown'),
            header = $('.header');
        if (user && user.is_logged_in) {
            header.find('.burger.login-toggle').addClass('hidden');
            loginButtonContainers
                .find(".btn--login").addClass("hidden").end()
                .find(".btn--join").addClass("hidden");
            loggedInDropdown.removeClass("hidden")
                .find(".logged-in__title").html($.trim(user.first_name + " " + user.last_name));
        } else {
            loginButtonContainers
                .find(".btn--login").removeClass("hidden").end()
                .find(".btn--join").removeClass("hidden").end();
            loggedInDropdown.addClass("hidden");
        }
    }
};

export default exports;
