import "bootstrap";
import "bootstrap-select";
import general from "general";
import geolocation from "general/promises/geolocation";
import forEach from "lodash/forEach";
import trim from "lodash/trim";
import "general/poly/string";
import "typeahead.js";
import { element } from "prop-types";


let currentPosition = document.querySelector("[data-use-radius-search='true']")
    ? new Promise((resolve) => resolve())
    : geolocation.currentPosition,
    latLonPattern = /^\s*-?\d+(\.\d+)?,-?\d+(\.\d+)?\s*$/i,
    boundsPattern = /^\s*-?\d+(\.\d+)?,-?\d+(\.\d+)?,-?\d+(\.\d+)?,-?\d+(\.\d+)?$\s*/i,
    proximitySearchString = "Near Me";

let defaultSearchByLocationOptions = [];
const getOptionsObject = (element) => {
    if(element.options && element.options.length > 0) {
        const results = [...element.options].map((e, i) => {
            return { 
                'key': e.value,
                'value': e.label
            };
        });
        return results;
    }
    return [];
}

const removeDuplicates = (results) => {
    const options = [];
    results.forEach((result) => {
        if (result) {
            const check = options.find((option) => {
                return option.label === result;
            });
            if (check) {
                return;
            }
            options.push({
                name: result,
                label: result
            });
        }
    });
    return options;
};

var exports = {
    encodeStringForSearch(theString) {
        return encodeURIComponent(theString.replace(/\s+/g, " ").replace(/[^-A-Za-z0-9à-üÀ-Ü ]/g, "").toLowerCase()).replace(/%20/g, "-");
    },
    encodeWhatOrWho(whatOrWho) {
        if (typeof whatOrWho != "string" || !whatOrWho) return "all";
        return exports.encodeStringForSearch(whatOrWho);
    },
    encodeWhere: async function(where) {
        if (typeof where != "string" || !where) return "anywhere";
        if (latLonPattern.test(where) || boundsPattern.test("where")) return where;
        if (where.toLocaleLowerCase() === proximitySearchString.toLocaleLowerCase()) {
            try {
                let position = await currentPosition;
                where = `${position.coords.latitude},${position.coords.longitude}`;
                return where;
            } catch (err) {}
        }
        where = exports.encodeStringForSearch(where);
        return where;
    },
    search: async function(whatOrWho, where, parameters) {
        var encodedWhatOrWho = exports.encodeWhatOrWho(whatOrWho),
            encodedWhere = await exports.encodeWhere(where),
            params = $.param(parameters || {}),
            searchUrl = `/${encodedWhatOrWho}/${encodedWhere}/?${params}`;
        if ($("#page-data").data("virtual-search-mode") === true) {
            searchUrl = (searchUrl.slice(-1) === '/')
                ? searchUrl + '?is_virtual=true'
                : searchUrl + '&is_virtual=true';
        }
        if ($("#page-data").data("home-visit-search-mode") === true) {
            searchUrl = (searchUrl.slice(-1) === '/')
                ? searchUrl + '?is_home_visit=true'
                : searchUrl + '&is_home_visit=true';
        }
        if (whatOrWho || where) {
            if (whatOrWho != "by-name") general.storage.setItem("lastLocationSearchedFor", where);
            general.storage.setItem("quotedLastLocationSearchedFor", encodedWhatOrWho);
            window.location.href = searchUrl;
            return true;
        }
        return false;
    }
};

async function init() {
    const hwhSearchByLocation = document.getElementById('hwh-search-by-location');
    if (hwhSearchByLocation && hwhSearchByLocation.length > 0) {
        defaultSearchByLocationOptions.push(...getOptionsObject(hwhSearchByLocation));
    }
    
    var searchForms = $(".search-form"),
        searchButtons = searchForms.find(".by-name-search, .by-profession-location-search"),
        locationSelect = searchForms.find("select.search-by-location"),
        specialitySearches = searchForms
            .find("select.js-select.no-select")
            .selectpicker()
            .next(".bootstrap-select")
                .find(".bs-searchbox")
                    .end()
                .find(".placeholder-option")
                    .parent()
                        .remove()
                        .end()
                    .end()
                .end()
            .on("change", (e) => {
                if (locationSelect.length > 0) {
                    let self = $(e.currentTarget);
                    let value = self.selectpicker("val");
                    let profession_locality_map = locationSelect.data("profession-locality-map");
                    let localities = profession_locality_map[value];
                    
                    if ((localities) && (localities.length > 0)) {
                        locationSelect.children("option").slice(1).remove();

                        forEach(localities, (locality) => {
                            if (defaultSearchByLocationOptions.length > 0) {
                                defaultSearchByLocationOptions.forEach((e) => {
                                    if (e['key'] === locality) {
                                        locationSelect.append(`<option value="${locality}">${e.value}</option>`);
                                    }
                                });
                            } else {
                                locationSelect.append(`<option value="${locality}">${locality}</option>`);
                            }
                        });

                        locationSelect.selectpicker("refresh");
                    }
                }
            }),
        nameSearches = searchForms
            .find("input.field--search.field--search-by-name")
            .typeahead(
                {
                    minLength: 3,
                    highlight: true
                }, {
                    name: "who",
                    displayKey: "label",
                    source: async function (query, process) {
                        let options = [];
                        if (query.length > 2) {
                            const results = await Promise.resolve(
                                $.get({
                                    url: `/suggest/who/${query}/`, 
                                        dataType: "json"
                                })
                            );
                            options = removeDuplicates(results);
                            return process(options);
                        }
                        
                    }
                }),
        lastLocationSearch = general.storage.getItem("lastLocationSearchedFor"),
        locationSearches = searchForms
            .find("input.field--search.field--search-by-location, input.field--search.field--search-by-location-only")
            .attr("placeholder", lastLocationSearch || proximitySearchString)
            .typeahead(
                {
                    minLength: 1,
                    highlight: true
                }, {
                    name: "where",
                    displayKey: "label",
                    source: async function (query, process) {
                        let options = [];
                        if (query.length > 2) {
                            let results = await Promise.resolve(
                                $.get({
                                    url: `/suggest/where/${query}/`, 
                                    dataType: "json"
                                })
                            );
                            options = removeDuplicates(results);
                        }
                        try {
                            await currentPosition;
                            options.unshift({
                                name: proximitySearchString,
                                label: proximitySearchString
                            });
                        } catch (err) {
                            console.log(err)
                        }
                        return process(options);
                    }
                }),
        virtualSearches = searchForms
            .find("input.field--search.field--search-by-virtual")
            .typeahead(
                {
                    minLength: 3,
                    highlight: true
                }, {
                    name: "where",
                    displayKey: "label",
                    source: async function (query, process) {
                        let options = [];
                        if (query.length > 2) {
                            let results = await Promise.resolve($.get({url: `/virtual-suggest/where/${query}/`, dataType: "json"}));
                            options = removeDuplicates(results);
                        }
                        try {
                            await currentPosition;
                            options.unshift({
                                name: proximitySearchString,
                                label: proximitySearchString
                            });
                        } catch (err) {
                            console.log(err)
                        }
                        return process(options);
                    }
                }),
        homeVisitSearches = searchForms
            .find("input.field--search.field--search-by-home-visit")
            .typeahead(
                {
                    minLength: 3,
                    highlight: true
                }, {
                    name: "where",
                    displayKey: "label",
                    source: async function (query, process) {
                        let options = [];
                        if (query.length > 2) {
                            let results = await Promise.resolve($.get({url: `/home-visit-suggest/where/${query}/`, dataType: "json"}));
                            options = removeDuplicates(results);
                        }
                        try {
                            await currentPosition;
                            options.unshift({
                                name: proximitySearchString,
                                label: proximitySearchString
                            });
                        } catch (err) {
                            console.log(err)
                        }
                        return process(options);
                    }
                });

    searchForms.find(".bootstrap-select.live-search .bs-searchbox").each(function (idx, o) {
        var o = $(o);
        var parent = o.parents(".bootstrap-select");
        var detached = o.detach().append("&nbsp;<span class='caret'></span>");
        parent.prepend(detached);
    });

    var placeholder = locationSearches.attr("placeholder");
    if (placeholder && !placeholder.startsWith("e.g.") && !placeholder.startsWith(proximitySearchString) && lastLocationSearch) {
        locationSearches.attr("placeholder", "e.g. " + placeholder);
    }

    searchButtons.click(async function (event) {
        event.preventDefault();

        var searchButton = $(this),
            byNameSearch = searchButton.hasClass("by-name-search"),
            byVirtualSearch = searchButton.hasClass("by-virtual-search"),
            byHomeVisitSearch = searchButton.hasClass("by-home-visit-search"),
            isVirtualClinicSearch = searchButton.hasClass("by-virtual-search-clinic"),
            searchForm = searchButton.parents(".search-form"),
            specialitySelect = searchForm.find("select.js-select"),
            nameSearch = searchForm.find("input.field--search.field--search-by-name").last(),
            virtualSearch = searchForm.find("input.field--search.field--search-by-virtual").last(),
            homeVisitSearch = searchForm.find("input.field--search.field--search-by-home-visit").last(),
            locationSearch = searchForm.find("input.field--search.field--search-by-location," +
                "input.field--search.field--search-by-location-only").last(),
            locationSelect = searchForm.find("select.search-by-location"),
            whatOrWho = byNameSearch ? "by-name" : specialitySelect.val();
        /* NOTE(jan): We support two types of controls: location search boxes
           and location dropdowns. A specific white label might have one or
           the other. */
        let where = null;
        if (byNameSearch) {
            where = nameSearch.val();
        } else if (locationSearch.length > 0) {
            where = locationSearch.val();
            if (!where) {
                where = locationSearch.attr("placeholder")
                    .replace(/^e\.g\.\s+/, "");
            }
        } else if (locationSelect.length > 0) {
            where = locationSelect.val();
        } else if (byVirtualSearch) {
            whatOrWho = isVirtualClinicSearch ? 'clinic' : 'all';
            where = virtualSearch.val();
        } else if (byHomeVisitSearch) {
            where = homeVisitSearch.val();
        }

        // NOTE(bevan): if result is true,
        // exports.search changes the location.href
        // everything afterwards will basically be fire and forget
        let result = null;
        if (byVirtualSearch) {
            result = await exports.search(
                trim(whatOrWho),
                trim(where),
                {'is_virtual': true},
            );
        } else if (byHomeVisitSearch) {
            result = await exports.search(
                trim(whatOrWho),
                trim(where),
                {'is_home_visit': true},
            );
        } else {
            result = await exports.search(trim(whatOrWho), trim(where));
        }
        
        if (!result) specialitySelect.focus();

        /* NOTE(jan): When computing the search URL, whatOrWho comes
        first, and where comes second. But for by-name searches,
        the first part is 'by-name' and the second part is the name.
        Due to this, 'whatOrWho' will be set to 'by-name' for by
        name searches, and 'where' will be set to the name. Below,
        this is corrected for event recording. */
        let label = `whatOrWho=${whatOrWho},where=${where}`;
        if (byNameSearch) {
            label = `whatOrWho=${where},where=By Name`;
        }
        if (byVirtualSearch) {
            label = `${label},type=By Virtual`;
        }
        if (byHomeVisitSearch) {
            label = `${label},type=By Home Visit`;
        }
        general.registerUserActivity(
            "Search", "Click", label, 1
        );
    });

    const selectElem = document.querySelector('#select-and-redirect');
    if (selectElem) {
        selectElem.addEventListener('change', (event) => {
            const location = selectElem.selectedOptions[0].value;
            if (location) {
                window.location.href = `/${location}`;
                return;
            }
        });
    }
};

window.addEventListener("pageshow", () => {
    const selectElem = document.querySelector("#select-and-redirect");
    if (selectElem) {
      selectElem.selectedIndex = 0;

      const parentElem = selectElem.parentElement.querySelector("[data-id=select-and-redirect]");
      if (parentElem) {
          parentElem.title = parentElem.children[0].innerText = selectElem.options[0].text;
      }
    }
});


let encodeStringForSearch = exports.encodeStringForSearch;
export { init, encodeStringForSearch };
