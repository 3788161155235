import React from "react";
import AddressAutocomplete from "general/components/AddressAutocomplete";
import Dropdown from "general/components/Dropdown";
import Pill from "general/components/Pill";

const AddressAutocompleteRadiusSearch = (props) => {
  const [where, setWhere] = React.useState(props.where);

  return (
    <div className="rm--address-autocomplete-radius-search">
      <AddressAutocomplete
        onChange={({ value }) => {
          if (value.length === 0) {
            setWhere(null);
            if (props.enableSubmit) {
              props.enableSubmit();
            }
          }
        }}
        onSelect={({ address }) => {
          const where = {
            label: address.formattedAddress,
            lat: address.latitude,
            lng: address.longitude,
            types: address.types
          };
          if (props.enableSubmit) {
            props.enableSubmit();
          }

          return [true, where.label, () => {
            setWhere(where)
            if (props.onResult) {
              props.onResult()
            }
          }];
        }}
        onBlur={() => {
          if (props.events?.onBlur) {
            props.events.onBlur()
          }
        }}
        onFocus={() => {
          if (props.events?.onFocus) {
            props.events.onFocus()
          }
        }}
        onPillClear={() => setWhere(null)}
        inputor={({ makeId }) => {
          return {
            id: makeId("address-autocomplete-radius-search"),
            placeholder: props.placeholder,
            required: false,
            disabled: false,
          };
        }}
        showResult={where != null}
        components={{
          wrapper: ({ children }) => {
            return <React.Fragment>{children}</React.Fragment>;
          },
          error: props.components && props.components.error ? props.components.error : ({ error }) => {
            return <span data-error>{error}</span>;
          },
          fieldset: props.components && props.components.fieldset ? props.components.fieldset : ({children}) => <React.Fragment>{children}</React.Fragment>,
          loader: props.components && props.components.loader ? props.components.loader : null,
          result: ({ pill }) => {
            if (where) {
              return (
                <React.Fragment>
                  <input type="hidden" name="location" value={true} />
                  <input type="hidden" name="lat" value={where.lat} />
                  <input type="hidden" name="lng" value={where.lng} />
                  <input type="hidden" name="address" value={where.label} />
                  <input
                    type="hidden"
                    name="radius"
                    value={where.radius || props.radius}
                  />
                  <input type="hidden" name="types" value={where.types} />
                  <div data-results>
                    {pill({ value: where.label })}
                    {!props.hideRadiusDropdown && <Dropdown
                      selected={where.radius || props.radius}
                      onChange={(event) => {
                        setWhere({ ...where, radius: event.target.value });
                      }}
                      data={["5,km", "10,km", "15,km", "20,km", "25,km", "50,km", "100,km", "200,km"].map(
                        (item) => {
                          const [d, u] = item.split(",");
                          return { label: `${d} ${u}`, value: `${d}${u}` };
                        }
                      )}
                    />}
                  </div>
                </React.Fragment>
              );
            } else {
              return null;
            }
          },
        }}
        searchOptions={{
          types: [
            "street_number",
            "street_address",
            "route",
            "sublocality",
            "locality",
          ],
        }}
        captureSuggestions={props.captureSuggestions}
      />
    </div>
  );
};

export default AddressAutocompleteRadiusSearch;
