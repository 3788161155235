import React from "react";
import { QuerySearch, SearchForm } from "components/SearchForm";
import AddressAutocompleteRadiusSearch from "components/AddressAutocompleteRadiusSearch";
import Dropdown from "general/components/Dropdown";
import { encodeStringForSearch, getRandomArbitrary } from "general/utils";

const What = (props) => {
  return (
    <React.Fragment>
      {props.list ? (
        <Dropdown
          name="what"
          default={props.default || "all"}
          data={props.data}
          selected={props.selected}
          placeholder={props.placeholder}
        />
      ) : (
        <input type="hidden" name="what" value={props.default} />
      )}
    </React.Fragment>
  );
};

const LocationSearch = (props) => {
  const variantSettings = JSON.parse(props.variantSettings);
  const whatList = JSON.parse(props.whatList);
  const submitInputID = `location-search-submit-${getRandomArbitrary()}`;
  const [disableSubmit, setDisableSubmit] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("reset-ls-spinnery-buttons", function(event) {
      setDisableSubmit(false);
    });
  }, []);

  return (
    <SearchForm
      className={`location-search location-search--${
        variantSettings.what.list ? "3" : "2"
      }-cols`}
      name="ls"
      onSubmit={(name, data) => {
        setDisableSubmit(true);

        let path = "/search/results/";
        const search = new QuerySearch();

        search.add("variant", data.variant);

        const [type, id] = data.what.split("_");
        if (id) {
          search.add(type, id);
        }

        if (data.location == "true") {
          path = `${path}${data.lat}/${data.lng}/${data.radius}/`;

          search.add("address", data.address);
        }

        if (data.reason_type) {
          search.add("reason_type", data.reason_type);
        }
        window.location.href = `${path}${search.term}`;
      }}
    >
      <input type="hidden" name="variant" value={props.variant} />

      {variantSettings.telehealth && (
        <input type="hidden" name="reason_type" value="virtual" />
      )}

      <What
        {...variantSettings.what}
        data={whatList}
        selected={props.whatSelected}
        placeholder={props.whatPlaceholder}
      />

      <AddressAutocompleteRadiusSearch
        radius={props.locationRadius || props.defaultRadius}
        placeholder={
          props.locationPlaceholder || "Enter street address, suburb or city"
        }
        where={props.location || null}
        events={{
          onBlur: () => setDisableSubmit(true),
          onFocus: () => setDisableSubmit(false),
        }}
        enableSubmit={() => setDisableSubmit(false)}
        placesAutocompleteMinChars={parseInt(
          variantSettings["places_autocomplete_min_chars"]
        )}
      />

      <button
        id={submitInputID}
        ord="primary"
        type="submit"
        disabled={disableSubmit}
      >
        <rm-icon replace class="icon--svg-spinners icon--svg-spinners--ring-resize"></rm-icon>
        <i className="icon general-search"></i>
      </button>

      {props.showReasonTypes && (
        <React.Fragment>
          <div className="reason-type-input">
            <label>
              <input type="checkbox" name="reason_type" defaultChecked={props.reasonType.includes('in_person')} value="in_person" />
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="on">
                <title>In-Person</title>
                <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
              </svg>
              In Person
            </label>
            <label>
              <input type="checkbox" name="reason_type" defaultChecked={props.reasonType.includes('virtual')} value="virtual" />
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="on">
                <title>Virtual</title>
                <path d="M3.25 4A2.25 2.25 0 001 6.25v7.5A2.25 2.25 0 003.25 16h7.5A2.25 2.25 0 0013 13.75v-7.5A2.25 2.25 0 0010.75 4h-7.5zM19 4.75a.75.75 0 00-1.28-.53l-3 3a.75.75 0 00-.22.53v4.5c0 .199.079.39.22.53l3 3a.75.75 0 001.28-.53V4.75z" />
              </svg>
              Virtual
            </label>
            <label>
              <input type="checkbox" name="reason_type" defaultChecked={props.reasonType.includes('telephonic')} value="telephonic" />
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="on">
                <title>Telephonic</title>
                <path fillRule="evenodd" d="M2 3.5A1.5 1.5 0 013.5 2h1.148a1.5 1.5 0 011.465 1.175l.716 3.223a1.5 1.5 0 01-1.052 1.767l-.933.267c-.41.117-.643.555-.48.95a11.542 11.542 0 006.254 6.254c.395.163.833-.07.95-.48l.267-.933a1.5 1.5 0 011.767-1.052l3.223.716A1.5 1.5 0 0118 15.352V16.5a1.5 1.5 0 01-1.5 1.5H15c-1.149 0-2.263-.15-3.326-.43A13.022 13.022 0 012.43 8.326 13.019 13.019 0 012 5V3.5z" clipRule="evenodd" />
              </svg>
              Telephonic
            </label>
          </div>
        </React.Fragment>
      )}
    </SearchForm>
  );
};

export { LocationSearch };
