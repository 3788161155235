/**
 * The Message Modal is initialised using a Promise in order to allow the exported displayMessage function to be called
 * before the domReady event has fired.
 */

import "bootstrap-modalmanager";
import "bootstrap-modal";
import isFunction from "lodash/isFunction";

let messageModal = new Promise(function (resolve) {
    $(document).ready(function () {
        resolve($("#message-modal").modal({
            show: false
        }));
    });
});

/**
 * An object defining the available Message Modal classes.
 */
let messageClasses = {
    info: {
        title: "Info",
        clazz: "message-modal--info"
    },
    warning: {
        title: "Warning!",
        clazz: "message-modal--warning"
    },
    error: {
        title: "Error!",
        clazz: "message-modal--error"
    },
    forbidden: {
        title: "Fobidden",
        clazz: "message-modal--error"
    }
};

export default {
    /**
     * Exported in order to support customization.
     */
    messageClasses,
    /**
     * Instruct the Message Modal to display a message
     *
     * @param {string} messageText
     * @param {string} [messageClass] Optional. Defaults to info.
     * @param {Function} [onClose]
     * @param {string} [messageHeader] Optional. Can be used to over-ride modal title source based on value of
     * messageClass.
     */
    displayMessage: function(messageText, messageClass, onClose, messageHeader) {
        var messageClass = messageClass && messageClasses[messageClass] ? messageClass : "info",
            onClose = isFunction(onClose) ? onClose : $.noop;
        return messageModal.then(function (messageModal) {
            messageModal
                .addClass(messageClasses[messageClass].clazz)
                .find(".modal-title").html(messageHeader || messageClasses[messageClass].title).end()
                .find(".modal-body").html(messageText).end()
                .one("hide.bs.modal", function (e) {
                    messageModal.removeClass(messageClasses[messageClass].clazz);
                    onClose.call(this, e);
                })
                .modal("show");
        });
    }
};
