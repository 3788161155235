/**
 * String.startsWith polyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith
 */
if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}


/**
 * String.endsWith polyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith
 */
if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(searchString, position) {
        var subjectString = this.toString();
        if (position === undefined || position > subjectString.length) {
            position = subjectString.length;
        }
        position -= searchString.length;
        var lastIndex = subjectString.indexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
    };
}


/**
Converts a string to title case
 */
if (!String.prototype.toTitleCase) {
    String.prototype.toTitleCase = function() {
	var lower = s => s.toLowerCase();
	var upper = s => s.toUpperCase();
	
	// converts full words that have more than 2 characters to title case
	return this.replace(/(\b([a-zA-Z]{3,})\b)/g, lower).replace(/\b(\w)/g, upper);
	
	// alternative, but includes 2 letter words
	// return this.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
    }
}
