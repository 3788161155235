import React from "react";

class QuerySearch {
  terms;

  constructor() {
    this.terms = [];
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('service')) {
      this.terms.push(`service=${searchParams.get('service')}`)
    }
  }

  add(key, value) {
    this.terms.push(`${key}=${value}`);
  }

  get term() {
    return `?${this.terms.join("&")}`;
  }
}

const SearchForm = (props) => {
  const ref = React.useRef(null);

  const submit = () => {
    const data = Array.from(new FormData(ref.current).entries()).reduce(
      (curr, [k, v]) => {
        if (Object.keys(curr).includes(k)) {
          v = `${curr[k]},${v}`
        }
        return {
          ...curr,
          [k]: v,
        };
      },
      {}
    );
    props.onSubmit(props.name, data);
  };
  return (
    <form
      name={props.name}
      ref={ref}
      className={props.className}
      autoComplete="off"
      onKeyDown={(e) => {
        if (e.key == "Enter") {
          if ("autocomplete" in e.target.attributes) {
            e.preventDefault();
          } else {
            submit();
          }
        }
      }}
      onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}
    >
      {props.children}
    </form>
  );
};

export { QuerySearch, SearchForm };
