import storage from "general/poly/storage";

const getterSetter = (item) => [
  () => JSON.parse(storage.localStorage.getItem(item)),
  (data) => {
    storage.localStorage.setItem(item, JSON.stringify(data));
    return data;
  },
];

const session = {
  user: getterSetter("user"),
  workplace: getterSetter("workplace"),
  clear: () => storage.localStorage.clear(),
  hasInvalidUser: (user) => (
    Object.keys(user).length === 1 &&
    Object.keys(user).includes("is_logged_in") &&
    user.is_logged_in === true
  )
};

export default session;
