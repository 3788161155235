import React from "react";
import usePlacesAutocomplete, {
  getLatLng,
  getGeocode,
} from "use-places-autocomplete";
import Pill from "general/components/Pill";
import { genericParseGeocodeResults } from "general/utils";

let cachedVal = "";
const acceptedKeys = ["ArrowUp", "ArrowDown", "Escape", "Enter"];

const placesAutocompleteMinChars = () => {
  const data = document.querySelector("#page-data");
  if (data) {
    return parseInt(data.dataset.placesAutocompleteMinChars);
  } else {
    return 4;
  }
};

const AddressAutocomplete = (props) => {
  const {
    ready,
    value,
    suggestions: { status, data, loading },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: ["za"],
      },
      ...props.searchOptions,
    },
    debounce: 200,
  });
  const [str, setStr] = React.useState("");
  const [currIndex, setCurrIndex] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [valid, setValid] = React.useState(false);

  const hasSuggestions = status == "OK";

  const reset = () => {
    setValue("");
    setStr("");
    setValid(false);
  };

  React.useEffect(() => {
    if (props.captureSuggestions && status.length && value.length) {
      props.captureSuggestions({
        term: value,
        total: data.length,
      })
    }
  }, [data])

  const onChange = (event) => {
    let value = event.target.value;
    setStr(value);
    if (value.length + 1 > placesAutocompleteMinChars()) {
      setValue(value);
      setError(null);
      setValid(false);
    }

    if (props.onChange) {
      props.onChange({ event, value });
    }
  };

  const onSelect = (suggestion) => {
    const { description } = suggestion;
    return async () => {
      setValue(description, false);
      clearSuggestions();

      const addressResult = await getGeocode({ address: description });
      const parsedAddress = genericParseGeocodeResults(
        addressResult[0],
        props.mapAddressComponents
      );
      if (props.onSelect) {
        const [isAddressValid, data, callback] = props.onSelect({
          address: parsedAddress,
          raw: addressResult,

          input: inputRef.current
        });
        if (isAddressValid) {
          setValid(true);
        } else {
          setValid(false);
          setError(data);
          setValue("");
        }

        typeof callback === "function" && callback(isAddressValid);
      }
    };
  };

  const onKeyDown = async (e) => {
    if (!hasSuggestions || !acceptedKeys.includes(e.key)) return;

    if (e.key === "Enter" && currIndex != null) {
      e.preventDefault();
      const fun = onSelect(data[currIndex]);
      await fun();
      return;
    }

    if (e.key === "Escape") {
      e.preventDefault();
      setCurrIndex(null);
      clearSuggestions();
      return;
    }

    let nextIndex;

    if (e.key === "ArrowUp") {
      e.preventDefault();
      nextIndex = currIndex == null ? data.length : currIndex;
      nextIndex = nextIndex && nextIndex > 0 ? nextIndex - 1 : null;
      nextIndex = nextIndex == null ? data.length - 1 : nextIndex;
    } else {
      nextIndex = currIndex == null ? -1 : currIndex;
      nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null;
      nextIndex = nextIndex == null ? 0 : nextIndex;
    }

    setCurrIndex(nextIndex);
    setValue(data[nextIndex] ? data[nextIndex].description : cachedVal, false);
  };

  const onMouseEnter = (idx) => () => {
    setCurrIndex(idx);
  };

  const onMouseLeave = () => {
    setCurrIndex(null);
  };

  const onBlur = () => {
    if (value && currIndex == null) {
      setError("You must select a location");
      clearSuggestions();
      if (props.onBlur) {
        props.onBlur();
      }
    }
  };

  const onFocus = () => {
    setValue("");
    setStr("");
    setError(null);
    if (props.onFocus) {
      props.onFocus();
    }
  };

  const onPillClear = () => {
    reset();
    if (props.onPillClear) {
      props.onPillClear();
    }
  };

  const renderSuggestions = () => {
    const suggestions = data.map((suggestion, idx) => {
      const {
        place_id,
        description,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div
          className={`suggestion-item ${
            idx == currIndex
              ? "suggestion-item--active"
              : "suggestion-item--inactive"
          }`}
          key={place_id}
          onClick={onSelect(suggestion)}
          onMouseEnter={onMouseEnter(idx)}
        >
          <span>{description}</span>
        </div>
      );
    });

    return (
      <div className="relative-wrapper">
        <div
          className={`suggestion-list-container has-suggestions`}
          onMouseLeave={onMouseLeave}
        >
          {suggestions}
          <div className="autocomplete-google-logo">
            <img src={`${globalConfig.staticRoot}/images/recomed/powered-by-google.svg`} />
          </div>
        </div>
      </div>
    );
  };

  const Wrapper = props.components.wrapper
    ? props.components.wrapper
    : ({ children }) => <React.Fragment>{children}</React.Fragment>;

  const makeId = (prefix) => `${prefix}__address-autocomplete-input`;
  const inputProps = {
    ...props.inputor({ makeId }),
    type: "text",
    autoComplete: "do-not-autofill",
    "data-is-address-valid": valid,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
  };

  const Result = props.components.result
    ? props.components.result
    : ({ pill }) => <React.Fragment>{pill({ value })}</React.Fragment>;

  const inputRef = React.useRef(null)

  React.useEffect(() => {
    if (props.initialValue) {
      setValue(props.initialValue);
      setValid(true)
    }
  }, [])

  const Fieldset = props.components.fieldset;

  return (
    <div className="rm--address-autocomplete">
      <Wrapper error={status == "ZERO_RESULTS"} makeId={makeId} reset={reset}>
        {valid || props.showResult ? (
          <Result
            value={value}
            pill={({ value }) => {
              return <Pill value={value} onClear={onPillClear} />;
            }}
          />
        ) : (
          <React.Fragment>
            {Fieldset && <Fieldset id={inputProps.id}>
              <input ref={inputRef} value={str} {...inputProps} />
              {props.components.loader && loading && props.components.loader()}
            </Fieldset>
            }

            {!Fieldset && <React.Fragment>
              <input ref={inputRef} value={str} {...inputProps} />
              {props.components.loader && loading && props.components.loader()}
              </React.Fragment>
            }

            {props.renderSuggestionsInWrapper &&
              hasSuggestions &&
              renderSuggestions()}
          </React.Fragment>
        )}
      </Wrapper>

      {status == "ZERO_RESULTS" &&
        props.components.error &&
        props.components.error({ error: "No Results" })}
      {error && props.components.error && props.components.error({ error })}

      {!props.renderSuggestionsInWrapper &&
        hasSuggestions &&
        renderSuggestions()}
    </div>
  );
};

export default AddressAutocomplete;
