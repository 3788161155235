import constants from "general/constants";
import general from 'general';

$(document).ready(function () {
    let footerMain = $('.js-footer-main');

    $('.js-footer-toggle').click(function () {
        footerMain.toggleClass(constants.activeClass);
        window.scrollTo(0,document.body.scrollHeight);
    });

  $('.link-cta-telehealth').click(() => {
    general.registerUserActivity('Footer', 'Clicked', 'Telehealth', '1');
  });
});
