/**
 * Poly-fill for missing browser-side localStorage
 */

function isLocalStorageSupported() {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

function isSessionStorageSupported() {
  var test = "test";
  try {
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

var exports = {
  localStoragePolyfilled: false,
  localStoragePolyfillFailed: false,
  sessionStoragePolyfilled: false,
  sessionStoragePolyfillFailed: false,
};
function noOp() {}
function notImplemented() { throw "Not Implemented"; }
/**
 * Constructor for AbstractStorage system
 *
 * @constructor
 */
function AbstractStorage() { }
AbstractStorage.prototype = {
  constructor: AbstractStorage,
  length: 0,
  _data: {},
  _clearData: notImplemented,
  _getData: notImplemented,
  _setData: notImplemented,
  clear: function () {
    this._data = {};
    this.length = 0;
    this._clearData();
  },
  getItem: function (key) {
    return this._data[key] === undefined ? null : this._data[key];
  },
  key: function (i) {
    var ctr = 0;
    for (var k in this._data) {
      if (ctr == i) {return k;}
      ctr++;
    }
    return null;
  },
  removeItem: function (key) {
    delete this._data[key];
    this.length--;
    this._setData(this._data);
  },
  setItem: function (key, value) {
    this._data[key] = value + ""; // forces the value to a string
    this.length++;
    this._setData(this._data);
  },
};
/**
 * Constructor for CookieStorage based on AbstractStorage
 *
 * @constructor
 */
function CookieStorage(storageType) {
  this._storageType = storageType;
  this._data = this._getData();
}
CookieStorage.prototype = new AbstractStorage();
CookieStorage.prototype.constructor = CookieStorage;
CookieStorage.prototype._createCookie = function (name, value, days) {
  let expires = "";
  let security = "; SameSite=Strict";

  if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

      expires = "; expires=" + date.toUTCString();
  }
  if (window.location.protocol == "https:") {
      security += "; Secure"
  }

  document.cookie = name + "=" + (value || "") + expires + security + "; path=/";
};
CookieStorage.prototype._readCookie = function (name) {
  var nameEQ = name + "=",
    ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {c = c.substring(1, c.length);}
    if (c.indexOf(nameEQ) == 0) {return c.substring(nameEQ.length, c.length);}
  }
  return null;
};
CookieStorage.prototype._clearData = function () {
  if (this._storageType == "session") {
    this._createCookie(`sessionStorage${window.name}`, "", 365);
  } else {
    this._createCookie("localStorage", "", 365);
  }
};
CookieStorage.prototype._getData = function () {
  var data = (this._storageType == "session")
    ? this._readCookie(`sessionStorage${window.name}`)
    : this._readCookie("localStorage");
  return data ? JSON.parse(data) : {};
};
CookieStorage.prototype._setData = function (data) {
  if (this._storageType == "session") {
    this._createCookie(`sessionStorage${window.name}`, JSON.stringify(data), 365);
  } else {
    this._createCookie("localStorage", JSON.stringify(data), 365);
  }
};
/**
 * Constructor for WindowDotNameStorage based on AbstractStorage
 *
 * @constructor
 */
function WindowDotNameStorage() {
  this._data = this._getData();
}
WindowDotNameStorage.prototype = new AbstractStorage();
WindowDotNameStorage.prototype.constructor = WindowDotNameStorage;
WindowDotNameStorage.prototype._clearData = function () {
  window.name = "";
};
WindowDotNameStorage.prototype._getData = function () {
  var data = window.name;
  return data ? JSON.parse(data) : {};
};
WindowDotNameStorage.prototype._setData = function (data) {
  window.name = JSON.stringify(data);
};
/**
 * Constructor for DummyStorage based on AbstractStorage
 *
 * @constructor
 */
function DummyStorage() { }
DummyStorage.prototype = new AbstractStorage();
DummyStorage.prototype.constructor = DummyStorage;
DummyStorage.prototype._clearData = noOp;
DummyStorage.prototype._getData = function () {
  return {};
};
DummyStorage.prototype._setData = noOp;
if (!isLocalStorageSupported()) {
  try {
    window.localStorage = new CookieStorage("local");
    exports.localStorage = window.localStorage;
    exports.localStoragePolyfilled = true;
  } catch (exception) {
    console.warn("Failed to polyfill window.localStorage. Fallback storage will be used via recomed/poly/storage module");
    exports.localStorage = new WindowDotNameStorage();
    exports.localStoragePolyfillFailed = true;
  }
}
else {
  exports.localStorage = window.localStorage;
}

if (!isSessionStorageSupported()) {
  try {
    window.sessionStorage = new CookieStorage("session");
    exports.sessionStorage = window.sessionStorage;
    exports.sessionStoragePolyfilled = true;
  } catch (exception) {
    console.warn("Failed to polyfill window.sessionStorage. Fallback storage will be used via recomed/poly/storage module");
    exports.sessionStorage = new WindowDotNameStorage();
    exports.sessionStoragePolyfillFailed = true;
  }
} else {
  exports.sessionStorage = window.sessionStorage;
}

export default exports;
