import React from "react";
import general from "general";

const IntercomPractitionerSwitcher = ({ practitioners }) => {
  if (practitioners.length === 0) {
    return null;
  }

  const [selected, setSelected] = React.useState(
    practitioners[0].id
  );

  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  React.useEffect(() => {
    async function fetchIntercomData() {
      await general.callBFF(
        `/account/intercom/${selected}/`,
        "GET",
        null, // payload
        null, // callbacks
        null, // genericSucccessCallbacks
        () => {}, // genericFailureCallbacks,
        true, // async
        false, // cache
        true, // disableGenericErrorHandling
        {}, // headers
      ).then(function(response) {
        window.intercomSettings = response;
        Intercom("update", {
          last_request_at: parseInt(Date.now() / 1000),
        });
      });
    }

    fetchIntercomData()
  }, [selected]);

  return (
    <React.Fragment>
      {practitioners.length > 1 &&
        <select value={selected} onChange={handleChange}>
          {practitioners.map((practitioner) => (
            <option
              key={practitioner.id}
              value={practitioner.id}
            >
              {practitioner.full_name}
            </option>
          ))}
        </select>
      }
      {practitioners.length === 1 &&
        <h3>{practitioners[0].full_name}</h3>
      }
    </React.Fragment>
  );
};

export default IntercomPractitionerSwitcher;
