import React from "react";
import { Alert } from "./Alert";
import { useAlert } from "./hooks";

const LandingAlert = ({ pgName }) => {

  const [alert] = useAlert(`alert/${pgName}/`);

  return <React.Fragment>{alert && <Alert alert={alert} />}</React.Fragment>;
};

export { LandingAlert };
