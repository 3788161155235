import general from "general";
import constants from "general/constants";
import {init as initSearch} from "general/components/search";

var $document = $(document),
    $window = $(window),
    duration = constants.duration,
    activeClass = constants.activeClass,
    scrollClass = constants.scrollClass,
    scrollVisibleClass = constants.scrollVisibleClass,
    activeSearchClass = constants.activeSearchClass,
    exports = {};

$document.ready(function () {
    var headerHome = $('.js-header-home'),
        searchToggle = $('.search-toggle'),
        fixedMenuSearch = $('.fixed-menu-search'),
        loginToggle = $('.login-toggle'),
        fixedMenuLogin = $('.fixed-menu-login');

    searchToggle.click(function () {
        $('.fixed-menu:not(.fixed-menu-search).' + activeClass).toggleClass(activeClass);
        fixedMenuSearch.toggleClass(activeClass);
    });

    loginToggle.click(function () {
        $('.fixed-menu:not(.fixed-menu-login).' + activeClass).toggleClass(activeClass);
        fixedMenuLogin.toggleClass(activeClass);
    });

    // NOTE(jan): Header --- logout.
    $(".btn--logout").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        general.endSession();
    });

    // scroll
    $document.scroll(function () {
        var viewport = $window.width(),
            viewportWidthLg = constants.vpLg;

        if ((viewport >= viewportWidthLg) && ($document.scrollTop() >= 200)) {
            headerHome.addClass(scrollClass);
        } else {
            headerHome.removeClass(scrollClass);
        }
        if ((viewport >= viewportWidthLg) && ($document.scrollTop() >= 747)) {
            headerHome.addClass(scrollVisibleClass);
        } else {
            headerHome.removeClass(scrollVisibleClass);
        }
    });


    if (document.querySelector('.js-header-home') == null) {
        initSearch();
    }

});

export default exports;
