import React from "react";
// @ts-ignore
import general from "../general";

type CSSPadding = {
  paddingRight?: string;
  paddingLeft?: string;
  padding?: string;
}

type Banner = {
  name: string;
  position: number;
  description: string;
  url: string;
  image: string;
}

type BannerProps = {
  patientGroup: string;
}

const BannerLandingListing = (props: BannerProps): React.ReactElement => {
  const [banners, setBanners] = React.useState([]);

  React.useEffect(() => {
    async function _fetchBanners () {
      const result = await general.callApi(
        "banner/",
        "GET",
        {
          // eslint-disable-next-line camelcase
          banner_type: "landing",
          // eslint-disable-next-line camelcase
          patient_group__name: props.patientGroup,
        }
      );
      if (result.meta.total_count > 0) {
        setBanners(result.objects);
      } else {
        setBanners([]);
      }
    }
    _fetchBanners();

  }, [props.patientGroup]);

  const calculatePadding = (index: number): CSSPadding => {
    const padding: CSSPadding = {};
    if (document.body.clientWidth > 985 && banners.length > 1) {
      if (index % 2 == 0) {
        padding["paddingRight"] = "0px";
      } else {
        padding["paddingLeft"] = "0px";
      }
    } else {
      padding["padding"] = "0px";
    }
    return padding;
  };

  let bootstrapClasses: string;
  if (banners.length === 1) {
    bootstrapClasses = "col-sm-offset-2 col-sm-8";
  } else {
    bootstrapClasses = "col-sm-6";
  }

  return (
    <div className="container">
      {banners.length > 0 && banners.map((banner: Banner, index) => {
        return (
          <div
            key={`${props.patientGroup}-${banner.position}`}
            className={`col-xs-12
              ${bootstrapClasses}
            `}
            style={{
              ...calculatePadding(index + 1),
              textAlign: "center",
              marginBottom: "10px",
            }}
          >
            <a
              href={banner.url}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                const label = [
                  banner.name,
                  `(${props.patientGroup})`,
                  `at position ${banner.position}`,
                ].join(" ");

                general.registerUserActivity(
                  "Banner",
                  "Clicked",
                  label,
                  0);
              }}
              title={banner.description}
              style={{
                cursor: "pointer",
              }}
            >
              <img
                className={"img-responsive"}
                src={banner.image}
                alt={banner.description}
              />
            </a>
          </div>
        );
      })}
    </div>
  );
};

export {
  BannerLandingListing,
};
