/**
 * A thin Promise layer over the navigator.geolocation object.
 */
let noOp = function () {};
export default {
    get currentPosition() {
        return new Promise(function (resolve, reject) {
            if (navigator.geolocation) navigator.geolocation.getCurrentPosition(resolve, reject);
            else reject(new Error("GeoLocation not available"));
        })
    },
    watchPosition: navigator.geolocation ? navigator.geolocation.watchPosition : noOp,
    clearWatch: navigator.geolocation ? navigator.geolocation.clearWatch : noOp
}
