const getTagDataAttributeValue = (tag_id, value) => {
    const element = document.getElementById(tag_id) || document.createElement("div");
    const attributeValue = element.getAttribute(value);
    return attributeValue;
}

export const getInstanceName = () => {
    return getTagDataAttributeValue("page-data", "data-instance-name");
}

export const getPatientGroup = () => {
    return getTagDataAttributeValue("page-data", "data-patient-group");
}

export const getWorkplaceNamefromSpan = () => {
    return getTagDataAttributeValue("workplace-data", "data-workplace-name");
}

export const getWorkplaceId = () => {
  return getTagDataAttributeValue("workplace-data", "data-workplace-id");
}

export const getPractitionerId = () => {
    return getTagDataAttributeValue("practitioner-data", "data-practitioner-id");
}

export const decode = (content) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = content;
  return txt.value;
};

export const genericParseGeocodeResults = (
  { address_components, formatted_address, geometry, types },
  MAP_ADDRESS_COMPONENTS
) => {
  let result = {};

  if (!MAP_ADDRESS_COMPONENTS) {
    MAP_ADDRESS_COMPONENTS = {
      streetNumber: "street_number",
      streetAddress: "route",
      suburb: "sublocality",
      city: "administrative_area_level_2",
      province: "administrative_area_level_1",
      postalcode: "postal_code",
    };
  }

  for (const [addrComponentKey, addrComponentType] of Object.entries(
    MAP_ADDRESS_COMPONENTS
  )) {
    for (const [
      _index,
      { short_name, long_name, types },
    ] of address_components.entries()) {
      if (types.includes(addrComponentType)) {
        result[addrComponentKey] = long_name;
      }
    }
  }

  if (geometry) {
    result["latitude"] = geometry.location.lat();
    result["longitude"] = geometry.location.lng();
  }

  if (types) {
    result["types"] = types.join(',')
  }

  result["formattedAddress"] = formatted_address

  return result;
};

export const parseGeocodeResults = (
  { address_components, geometry },
  MAP_ADDRESS_COMPONENTS = null
) => {
  const recomedResult = genericParseGeocodeResults(
    { address_components, geometry },
    MAP_ADDRESS_COMPONENTS
  );
  const streetNumber = recomedResult.streetNumber || "No";
  const streetAddress = recomedResult.streetAddress || "street";
  recomedResult["street"] = [streetNumber, streetAddress].join(" ");

  delete recomedResult["streetNumber"];
  delete recomedResult["streetAddress"];

  return recomedResult;
};

export const toggleFieldGroup = (sel) => {
  const el = document.querySelector(sel);
  return {
    on: () => {
      if (el) {
        el.style.display = "block";
        el.dataset.disabled = "false";
      }
    },
    off: () => {
      if (el) {
        el.style.display = "none";
        el.dataset.disabled = "true";
      }
    },
  };
};

export const practitionerSorter = (a, b) => {
  const nameA = [a.first_name.toUpperCase(), a.last_name.toUpperCase()].join(
    " "
  );

  const nameB = [b.first_name.toUpperCase(), b.last_name.toUpperCase()].join(
    " "
  );

  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const idToURI = (id, resource) => {
  return `/api/v1/${resource}/${id}/`;
};

export const uriToId = (uri) => {
  if (uri) {
    const [id] = uri.split("/").slice(-2, -1);
    return parseInt(id);
  } else {
    return null;
  }
};

export const capitalize = (word) => {
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`
};

export const truncate = (str, length = 15) => {
  if (str.length > length) {
    return `${str.slice(0, length)}...`;
  }
  return str;
}

export const selectSeoSlug = (practitioner, workplace) => {
  let seoSlug = workplace.workplace_type.book_at_workplace_level
    ? workplace.seo_slug
    : practitioner.seo_slug
  seoSlug = seoSlug.replaceAll("+", "/")
  return seoSlug
};

export const isValidZaIdNumber = (idNumber) => {
  try {
    let oddNumbers = [];
    let evenNumbers = [];

    [...idNumber.slice(0, -1)].forEach((number, index) => {
      if (index % 2) {
        evenNumbers.push((number));
      } else {
        oddNumbers.push(parseInt(number));
      }
    });

    let evenNumbersDoubled = [];
    [
      ...(parseInt(evenNumbers.join("")) * 2).toString(),
    ].forEach((evenNumber) => {
      evenNumbersDoubled.push(parseInt(evenNumber));
    });

    const total = (oddNumbers.reduce((pv, cv) => pv + cv, 0)
      + evenNumbersDoubled.reduce((pv, cv) => pv + cv, 0)
    ).toString();

    if (total.length != 2) {
      return false;
    }

    let lastDigit = (10 - [parseInt(total.slice(-1))]).toString().slice(-1);
    if (parseInt(idNumber.slice(-1)) != parseInt(lastDigit)) {
      return false;
    }
    return true;
  }
  catch (e) {
    return false;
  }
};

export const encodeStringForSearch = (theString) => {
  return encodeURIComponent(
    theString
      .replace(/\s+/g, " ")
      .replace(/[^-A-Za-z0-9 ]/g, "")
      .toLowerCase()
  ).replace(/%20/g, "-");
};

export function getRandomArbitrary(min = 0, max = 1024) {
  return Math.random() * (max - min) + min;
}

export const parseQuerySearch = () => {
  return Array.from(
    new URLSearchParams(window.location.search).entries()
  ).reduce((prev, [k, v]) => {
    return {
      ...prev,
      [k]: v,
    };
  }, {});
};


export const pluck = (obj, keys, options = { spreadObject: false }) => {
  if (obj == null || obj == undefined) {
    return {};
  }
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!keys.includes(key)) {
      return acc;
    }

    if (options.spreadObject && typeof value == "object") {
      return {
        ...acc,
        ...value,
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

