const source = () => document.querySelector("#page-data").dataset.instanceName;
const portal = () => document.querySelector("[data-portal]").dataset.portal;

const rmpp = "X-RM-RMPP";
const rmcp = "X-RM-RMCP";

const portals = {
  rmpp,
  rmcp
}

const _header = (h) => ({ [h]: source() });
const rmppHeader = () => _header(rmpp);

const setSourceHeader = (headers) => {
  return {
    ...headers,
    ..._header(portals[portal()])
  }
};


export { rmppHeader, setSourceHeader };
