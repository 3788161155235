import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap-select";
import "jquery.smoothscroll.js";
import "picturefill";
import "unslider";
import general from "general";
import constants from "general/constants";
import resource from "general/resource";
import {
  getPatientGroup,
} from "utils";
import "components/header";
import "components/footer";
import { init as initSearch } from "components/search";
import { BannerLandingListing } from "components/BannerLandingListing";
import { LocationSearch } from "components/LocationSearch";
import { LandingAlert } from "components/alert/LandingAlert";


$(document).ready(async function () {
    // slider - tabs
    var sliderTabItem = $('.js-slider-tabs-item'),
        sliderTabContentItem = $('.js-slider-tab-content-item'),
        activeClass = constants.activeClass,
        speed = constants.speed,
        delay = constants.delay;

    sliderTabItem.click(function () {
        var $this = $(this),
            index = $this.index();

        $this.addClass(activeClass);
        $this.siblings().removeClass(activeClass);

        sliderTabContentItem.eq(index).addClass(activeClass).siblings().removeClass(activeClass);
    });

    $(".search-alternatives")
        .find(".search-alternatives__by-speciality-location-link")
            .on("click", function () {
                $(".slider__tabs-item--by-speciality-location").trigger("click");
            })
            .end()
        .find(".search-alternatives__by-name-link")
            .on("click", function () {
                $(".slider__tabs-item--by-name").trigger("click");
            });


    // slider - about slider
    var unslider = $('.js-slider-about-container').unslider({
        arrows: {},
        autoplay: true,
        infinite: true,
        nav: false,
        speed: speed,
        delay: delay,
        fluid: true
    });

    // searches - pagination
    var searchItem = $('.js-search-item'),
        searchPagItem = $('.js-search-pag-item');

    searchPagItem.click(function() {
        var $this = $(this),
            index = $this.index();

        $this.addClass(activeClass).siblings().removeClass(activeClass);
        searchItem.eq(index).addClass(activeClass).siblings().removeClass(activeClass);
    });

    // scroll - smooth animation for a="#...
    $('a').smoothscroll();

    document.querySelectorAll("[data-react__location-search]").forEach((el) => {
        ReactDOM.render(
            <React.StrictMode>
                <LocationSearch {...el.dataset} />
            </React.StrictMode>,
            el
        )
    });

    const el = document.querySelector("#react-banners-landing");
    const patientGroup = getPatientGroup();
    if (el) {
        ReactDOM.render(
            <React.StrictMode>
                <BannerLandingListing
                    patientGroup={patientGroup}
                />
            </React.StrictMode>,
            el
        )
    }

    const alertEl = document.querySelector("#react-landing-alert");
    const pgName = getPatientGroup();
    if (alertEl) {
        ReactDOM.render(
            <React.StrictMode>
                <LandingAlert
                    pgName={pgName}
                />
            </React.StrictMode>,
            alertEl
        )
    }

    general.attachSpinnerEvent();
    general.registerUserActivity("Landing", "landing", "landing", "landing");

    initSearch();
});
