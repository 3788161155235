import React from "react";
import ReactDOM from "react-dom";
import IntercomPractitionerSwitcher from "general/components/IntercomPractitionerSwitcher";
import localSession from "general/localSession";
import guard from "general/guardClauses";

window.addEventListener("DOMContentLoaded", () => {
  if (guard(["hasUser", "hasWorkplace"])) {
    const intercomAppElem = document.querySelector("#react-intercom");
    const [getWorkplace] = localSession.workplace;
    const activeIntercomPractitioners = getWorkplace().practitioners.filter((p) => p.intercom_active);

    if (intercomAppElem && activeIntercomPractitioners.length) {
      window.intercomSettings = {
        app_id: intercomAppElem.dataset.id,
      };
    }

    if (window.Intercom) {
      Intercom("onShow", function() {
        const [getUser] = localSession.user;
        if (intercomAppElem && activeIntercomPractitioners.length) {
          ReactDOM.render(
            <React.StrictMode>
              <IntercomPractitionerSwitcher
                practitioners={activeIntercomPractitioners}
              />
            </React.StrictMode>,
            intercomAppElem
          );
        }
      });

      Intercom("onHide", function() {
        if (intercomAppElem) {
          ReactDOM.unmountComponentAtNode(intercomAppElem);
        }
      });
    }
  }
});
