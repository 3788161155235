import React from "react";

const Pill = (props) => {
  return (
    <div className="rm--pill">
      <p>
        <span>{props.value}</span>
        <i className="icon general-close" onClick={props.onClear}></i>
      </p>
    </div>
  );
};

export default Pill;
